import { render, staticRenderFns } from "./Hprocess.vue?vue&type=template&id=60b5416e&scoped=true&"
import script from "./Hprocess.vue?vue&type=script&lang=js&"
export * from "./Hprocess.vue?vue&type=script&lang=js&"
import style0 from "./Hprocess.vue?vue&type=style&index=0&id=60b5416e&scoped=true&lang=css&"
import style1 from "./Hprocess.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b5416e",
  null
  
)

export default component.exports
import {QLayout,QPageContainer,QPage,QCard,QCardSection,QTimeline,QTimelineEntry,QChip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QPageContainer,QPage,QCard,QCardSection,QTimeline,QTimelineEntry,QChip})
